import { Component, Index } from "solid-js";
import * as KoButton from "@kobalte/core/button";
import styles from "./ButtonBar.module.css";

type Button = {
  text: string;
  selected: boolean;
};

const Btn: Component<{
  button: Button;
  onClick: () => void;
  useExternalStyles: boolean;
}> = (props) => {
  return (
    <KoButton.Root
      class={
        props.useExternalStyles
          ? ""
          : `${styles.Button} ${props.button.selected ? styles.selected : ""}`
      }
      onClick={props.onClick}
      data-selected={props.button.selected}
    >
      {props.button.text}
    </KoButton.Root>
  );
};

const ButtonBar: Component<{
  buttons: Button[];
  onClick: (index: number) => void;
  wrap?: boolean;
  class?: string;
}> = (props) => {
  return (
    <div
      class={
        props.class
          ? props.class
          : `${styles.ButtonBar}  ${props.wrap ? styles.wrap : ""}`
      }
    >
      <Index each={props.buttons}>
        {(button, i) => (
          <Btn
            button={button()}
            onClick={() => props.onClick(i)}
            useExternalStyles={!!props.class}
          />
        )}
      </Index>
    </div>
  );
};

export default ButtonBar;
